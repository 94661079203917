import React from "react";
import { Link } from "react-router-dom";
import "./indexPage.css";

const IndexPage = () => {
  return (
    <div className="IndexPage">
      <h1 style={{ fontSize: "3em", color: "#333" }}>Welcome to my website</h1>
      <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/app">App</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </nav>
      <p style={{ fontSize: "1.2em", color: "#666" }}>
        This is the index page of our awesome website. Explore and enjoy!
      </p>
    </div>
  );
};
export default IndexPage;
