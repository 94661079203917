// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IndexPage {
  text-align: center;
  padding: 50px;
  font-family: Arial, sans-serif;
}

.IndexPage h1 {
  font-size: 3em;
  color: #333;
}
.IndexPage nav {
  margin: 20px 0;
}

.IndexPage ul {
  list-style-type: none;
  padding: 10;
}

.IndexPage li {
  display: inline;
  margin: 0 15px;
}

.IndexPage a {
  text-decoration: none;
  color: #007bff;
}
`, "",{"version":3,"sources":["webpack://./src/indexPage.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,cAAc;EACd,WAAW;AACb;AACA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB","sourcesContent":[".IndexPage {\n  text-align: center;\n  padding: 50px;\n  font-family: Arial, sans-serif;\n}\n\n.IndexPage h1 {\n  font-size: 3em;\n  color: #333;\n}\n.IndexPage nav {\n  margin: 20px 0;\n}\n\n.IndexPage ul {\n  list-style-type: none;\n  padding: 10;\n}\n\n.IndexPage li {\n  display: inline;\n  margin: 0 15px;\n}\n\n.IndexPage a {\n  text-decoration: none;\n  color: #007bff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
